import { default as _91_46_46_46slug_93mUWE4p6xGcMeta } from "/builds/tln/tln-app/pages/[...slug].vue?macro=true";
import { default as account_45aanmakenhfELFlYlHkMeta } from "/builds/tln/tln-app/pages/account-aanmaken.vue?macro=true";
import { default as _91slug_93j5OYWMERcmMeta } from "/builds/tln/tln-app/pages/actueel/[slug].vue?macro=true";
import { default as indexbG2DQYEFPFMeta } from "/builds/tln/tln-app/pages/actueel/index.vue?macro=true";
import { default as _91id_93ha5ODYLClcMeta } from "/builds/tln/tln-app/pages/bijeenkomsten/[slug]/[id].vue?macro=true";
import { default as index93U3YyPOfJMeta } from "/builds/tln/tln-app/pages/bijeenkomsten/index.vue?macro=true";
import { default as _91slug_93thaSVIErdXMeta } from "/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/[slug].vue?macro=true";
import { default as index8wfNuccdGDMeta } from "/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/index.vue?macro=true";
import { default as indexbQwXJe46q4Meta } from "/builds/tln/tln-app/pages/index.vue?macro=true";
import { default as _91slug_93jKvHT1qTtpMeta } from "/builds/tln/tln-app/pages/kennis/[thema]/[slug].vue?macro=true";
import { default as indexLwkOGibmGfMeta } from "/builds/tln/tln-app/pages/kennis/[thema]/index.vue?macro=true";
import { default as indexpVXDAmVOS9Meta } from "/builds/tln/tln-app/pages/kennis/index.vue?macro=true";
import { default as _91slug_93W6vJsDcJ3DMeta } from "/builds/tln/tln-app/pages/ledenvoordeel/[slug].vue?macro=true";
import { default as indexfUpgqxW4VXMeta } from "/builds/tln/tln-app/pages/ledenvoordeel/index.vue?macro=true";
import { default as dossiers8McGcSUhkiMeta } from "/builds/tln/tln-app/pages/mijn-tln/dossiers.vue?macro=true";
import { default as login_45handletWbXxISX0wMeta } from "/builds/tln/tln-app/pages/mijn-tln/login-handle.vue?macro=true";
import { default as loginXGk63WhmgEMeta } from "/builds/tln/tln-app/pages/mijn-tln/login.vue?macro=true";
import { default as logoutIDh6srtNIaMeta } from "/builds/tln/tln-app/pages/mijn-tln/logout.vue?macro=true";
import { default as _91id_93jnxTeo9NsGMeta } from "/builds/tln/tln-app/pages/mijn-tln/organisaties/[id].vue?macro=true";
import { default as indexaf88AfnxmKMeta } from "/builds/tln/tln-app/pages/mijn-tln/organisaties/index.vue?macro=true";
import { default as profielIS4cD63e32Meta } from "/builds/tln/tln-app/pages/mijn-tln/profiel.vue?macro=true";
import { default as _91slug_93bQpK61N1WBMeta } from "/builds/tln/tln-app/pages/opleidingen/[slug].vue?macro=true";
import { default as indexCh0cD9eVPxMeta } from "/builds/tln/tln-app/pages/opleidingen/index.vue?macro=true";
import { default as _91content_45type_934Xb0vkx04VMeta } from "/builds/tln/tln-app/pages/zoeken/[content-type].vue?macro=true";
import { default as indexEKX19EXwkfMeta } from "/builds/tln/tln-app/pages/zoeken/index.vue?macro=true";
import { default as nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta } from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdE } from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93mUWE4p6xGcMeta?.name ?? "slug",
    path: _91_46_46_46slug_93mUWE4p6xGcMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93mUWE4p6xGcMeta || {},
    alias: _91_46_46_46slug_93mUWE4p6xGcMeta?.alias || [],
    redirect: _91_46_46_46slug_93mUWE4p6xGcMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: account_45aanmakenhfELFlYlHkMeta?.name ?? "account-aanmaken",
    path: account_45aanmakenhfELFlYlHkMeta?.path ?? "/account-aanmaken",
    meta: account_45aanmakenhfELFlYlHkMeta || {},
    alias: account_45aanmakenhfELFlYlHkMeta?.alias || [],
    redirect: account_45aanmakenhfELFlYlHkMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/account-aanmaken.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93j5OYWMERcmMeta?.name ?? "actueel-slug",
    path: _91slug_93j5OYWMERcmMeta?.path ?? "/actueel/:slug()",
    meta: _91slug_93j5OYWMERcmMeta || {},
    alias: _91slug_93j5OYWMERcmMeta?.alias || [],
    redirect: _91slug_93j5OYWMERcmMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/actueel/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexbG2DQYEFPFMeta?.name ?? "actueel",
    path: indexbG2DQYEFPFMeta?.path ?? "/actueel",
    meta: indexbG2DQYEFPFMeta || {},
    alias: indexbG2DQYEFPFMeta?.alias || [],
    redirect: indexbG2DQYEFPFMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/actueel/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ha5ODYLClcMeta?.name ?? "bijeenkomsten-slug-id",
    path: _91id_93ha5ODYLClcMeta?.path ?? "/bijeenkomsten/:slug()/:id()",
    meta: _91id_93ha5ODYLClcMeta || {},
    alias: _91id_93ha5ODYLClcMeta?.alias || [],
    redirect: _91id_93ha5ODYLClcMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/bijeenkomsten/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: index93U3YyPOfJMeta?.name ?? "bijeenkomsten",
    path: index93U3YyPOfJMeta?.path ?? "/bijeenkomsten",
    meta: index93U3YyPOfJMeta || {},
    alias: index93U3YyPOfJMeta?.alias || [],
    redirect: index93U3YyPOfJMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/bijeenkomsten/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93thaSVIErdXMeta?.name ?? "hetluktalleenmetlogistiek-slug",
    path: _91slug_93thaSVIErdXMeta?.path ?? "/hetluktalleenmetlogistiek/:slug()",
    meta: _91slug_93thaSVIErdXMeta || {},
    alias: _91slug_93thaSVIErdXMeta?.alias || [],
    redirect: _91slug_93thaSVIErdXMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/[slug].vue").then(m => m.default || m)
  },
  {
    name: index8wfNuccdGDMeta?.name ?? "hetluktalleenmetlogistiek",
    path: index8wfNuccdGDMeta?.path ?? "/hetluktalleenmetlogistiek",
    meta: index8wfNuccdGDMeta || {},
    alias: index8wfNuccdGDMeta?.alias || [],
    redirect: index8wfNuccdGDMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/hetluktalleenmetlogistiek/index.vue").then(m => m.default || m)
  },
  {
    name: indexbQwXJe46q4Meta?.name ?? "index",
    path: indexbQwXJe46q4Meta?.path ?? "/",
    meta: indexbQwXJe46q4Meta || {},
    alias: indexbQwXJe46q4Meta?.alias || [],
    redirect: indexbQwXJe46q4Meta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jKvHT1qTtpMeta?.name ?? "kennis-thema-slug",
    path: _91slug_93jKvHT1qTtpMeta?.path ?? "/kennis/:thema()/:slug()",
    meta: _91slug_93jKvHT1qTtpMeta || {},
    alias: _91slug_93jKvHT1qTtpMeta?.alias || [],
    redirect: _91slug_93jKvHT1qTtpMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/kennis/[thema]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLwkOGibmGfMeta?.name ?? "kennis-thema",
    path: indexLwkOGibmGfMeta?.path ?? "/kennis/:thema()",
    meta: indexLwkOGibmGfMeta || {},
    alias: indexLwkOGibmGfMeta?.alias || [],
    redirect: indexLwkOGibmGfMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/kennis/[thema]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpVXDAmVOS9Meta?.name ?? "kennis",
    path: indexpVXDAmVOS9Meta?.path ?? "/kennis",
    meta: indexpVXDAmVOS9Meta || {},
    alias: indexpVXDAmVOS9Meta?.alias || [],
    redirect: indexpVXDAmVOS9Meta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/kennis/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W6vJsDcJ3DMeta?.name ?? "ledenvoordeel-slug",
    path: _91slug_93W6vJsDcJ3DMeta?.path ?? "/ledenvoordeel/:slug()",
    meta: _91slug_93W6vJsDcJ3DMeta || {},
    alias: _91slug_93W6vJsDcJ3DMeta?.alias || [],
    redirect: _91slug_93W6vJsDcJ3DMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/ledenvoordeel/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfUpgqxW4VXMeta?.name ?? "ledenvoordeel",
    path: indexfUpgqxW4VXMeta?.path ?? "/ledenvoordeel",
    meta: indexfUpgqxW4VXMeta || {},
    alias: indexfUpgqxW4VXMeta?.alias || [],
    redirect: indexfUpgqxW4VXMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/ledenvoordeel/index.vue").then(m => m.default || m)
  },
  {
    name: dossiers8McGcSUhkiMeta?.name ?? "mijn-tln-dossiers",
    path: dossiers8McGcSUhkiMeta?.path ?? "/mijn-tln/dossiers",
    meta: dossiers8McGcSUhkiMeta || {},
    alias: dossiers8McGcSUhkiMeta?.alias || [],
    redirect: dossiers8McGcSUhkiMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/dossiers.vue").then(m => m.default || m)
  },
  {
    name: login_45handletWbXxISX0wMeta?.name ?? "mijn-tln-login-handle",
    path: login_45handletWbXxISX0wMeta?.path ?? "/mijn-tln/login-handle",
    meta: login_45handletWbXxISX0wMeta || {},
    alias: login_45handletWbXxISX0wMeta?.alias || [],
    redirect: login_45handletWbXxISX0wMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/login-handle.vue").then(m => m.default || m)
  },
  {
    name: loginXGk63WhmgEMeta?.name ?? "mijn-tln-login",
    path: loginXGk63WhmgEMeta?.path ?? "/mijn-tln/login",
    meta: loginXGk63WhmgEMeta || {},
    alias: loginXGk63WhmgEMeta?.alias || [],
    redirect: loginXGk63WhmgEMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/login.vue").then(m => m.default || m)
  },
  {
    name: logoutIDh6srtNIaMeta?.name ?? "mijn-tln-logout",
    path: logoutIDh6srtNIaMeta?.path ?? "/mijn-tln/logout",
    meta: logoutIDh6srtNIaMeta || {},
    alias: logoutIDh6srtNIaMeta?.alias || [],
    redirect: logoutIDh6srtNIaMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/logout.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jnxTeo9NsGMeta?.name ?? "mijn-tln-organisaties-id",
    path: _91id_93jnxTeo9NsGMeta?.path ?? "/mijn-tln/organisaties/:id()",
    meta: _91id_93jnxTeo9NsGMeta || {},
    alias: _91id_93jnxTeo9NsGMeta?.alias || [],
    redirect: _91id_93jnxTeo9NsGMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/organisaties/[id].vue").then(m => m.default || m)
  },
  {
    name: indexaf88AfnxmKMeta?.name ?? "mijn-tln-organisaties",
    path: indexaf88AfnxmKMeta?.path ?? "/mijn-tln/organisaties",
    meta: indexaf88AfnxmKMeta || {},
    alias: indexaf88AfnxmKMeta?.alias || [],
    redirect: indexaf88AfnxmKMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: profielIS4cD63e32Meta?.name ?? "mijn-tln-profiel",
    path: profielIS4cD63e32Meta?.path ?? "/mijn-tln/profiel",
    meta: profielIS4cD63e32Meta || {},
    alias: profielIS4cD63e32Meta?.alias || [],
    redirect: profielIS4cD63e32Meta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/mijn-tln/profiel.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bQpK61N1WBMeta?.name ?? "opleidingen-slug",
    path: _91slug_93bQpK61N1WBMeta?.path ?? "/opleidingen/:slug()",
    meta: _91slug_93bQpK61N1WBMeta || {},
    alias: _91slug_93bQpK61N1WBMeta?.alias || [],
    redirect: _91slug_93bQpK61N1WBMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/opleidingen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexCh0cD9eVPxMeta?.name ?? "opleidingen",
    path: indexCh0cD9eVPxMeta?.path ?? "/opleidingen",
    meta: indexCh0cD9eVPxMeta || {},
    alias: indexCh0cD9eVPxMeta?.alias || [],
    redirect: indexCh0cD9eVPxMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/opleidingen/index.vue").then(m => m.default || m)
  },
  {
    name: _91content_45type_934Xb0vkx04VMeta?.name ?? "zoeken-contenttype",
    path: _91content_45type_934Xb0vkx04VMeta?.path ?? "/zoeken/:contenttype()",
    meta: _91content_45type_934Xb0vkx04VMeta || {},
    alias: _91content_45type_934Xb0vkx04VMeta?.alias || [],
    redirect: _91content_45type_934Xb0vkx04VMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/zoeken/[content-type].vue").then(m => m.default || m)
  },
  {
    name: indexEKX19EXwkfMeta?.name ?? "zoeken",
    path: indexEKX19EXwkfMeta?.path ?? "/zoeken",
    meta: indexEKX19EXwkfMeta || {},
    alias: indexEKX19EXwkfMeta?.alias || [],
    redirect: indexEKX19EXwkfMeta?.redirect,
    component: () => import("/builds/tln/tln-app/pages/zoeken/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta?.path ?? "/mijn-tln",
    meta: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta || {},
    alias: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta?.alias || [],
    redirect: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdEMeta?.redirect,
    component: nuxt_643_4611_462__64types_43node_6418_4619_4634_axios_641_467_462_eslint_648_4657_460_less_644_462_460_stylelint_6414_4616FIo3cKDRdE
  }
]