import validate from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45state_45global from "/builds/tln/tln-app/middleware/auth-state.global.ts";
import manifest_45route_45rule from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45state_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/tln/tln-app/middleware/auth.ts")
}