import revive_payload_client_mxsB8fuRJa from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_utNROVIihF from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BMbjZunEBb from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_LtRAAokh3I from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@0.8.5_axios@1.7.2_nuxt@3.11.2_postcss@8.4.38_vite@4.5.3_vue@3.4.27/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_1Rj0edeoke from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_ODAgvD5Vq5 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_H9Uopl6vgk from "/builds/tln/tln-app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/tln/tln-app/.nuxt/components.plugin.mjs";
import prefetch_client_8PLouzhqXR from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_f5bdce16_ie6uyWTU2g from "/builds/tln/tln-app/.nuxt/templates.pwa.client.f5bdce16.ts";
import plugin_oxc6WPJmRb from "/builds/tln/tln-app/node_modules/.pnpm/@digitalnatives+graphql-client@4.0.4_graphql@16.8.1_nuxt@3.11.2_vue@3.4.27/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_Te8lfITPyP from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-bugsnag@7.3.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_jKO9bg7aEc from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import chunk_reload_client_4NsHZexfCI from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.11.2_@types+node@18.19.34_axios@1.7.2_eslint@8.57.0_less@4.2.0_stylelint@14.16.1_types_z64nvyyv4e2iwa6qfon3shupzu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import formie_yNFepVHNmd from "/builds/tln/tln-app/plugins/formie.js";
import axios_QMFgzss1s4 from "/builds/tln/tln-app/plugins/axios.ts";
import gtm_cXsUZobmSF from "/builds/tln/tln-app/plugins/gtm.ts";
import msal_client_gxY03bskzg from "/builds/tln/tln-app/plugins/msal.client.ts";
export default [
  revive_payload_client_mxsB8fuRJa,
  unhead_utNROVIihF,
  router_BMbjZunEBb,
  _0_siteConfig_LtRAAokh3I,
  payload_client_1Rj0edeoke,
  check_outdated_build_client_ODAgvD5Vq5,
  plugin_vue3_H9Uopl6vgk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8PLouzhqXR,
  templates_pwa_client_f5bdce16_ie6uyWTU2g,
  plugin_oxc6WPJmRb,
  plugin_Te8lfITPyP,
  nuxt_plugin_jKO9bg7aEc,
  chunk_reload_client_4NsHZexfCI,
  formie_yNFepVHNmd,
  axios_QMFgzss1s4,
  gtm_cXsUZobmSF,
  msal_client_gxY03bskzg
]